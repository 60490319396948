@import './src/scss/variables';
@import './src/scss/game';

.game__content {
	margin: auto;
	border: none;
}

.sudoku__container {
	border: 1px solid;
	//height: 500px;
  //width: 500px;
}

.game__container {
  flex-direction: column;

  .grid {
    aspect-ratio: 1 / 1;
    flex: 6;
    margin: auto;
    width: 538px;
  }

  .game__controls {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;

    &.disabled {
      span {
        cursor: none;
        pointer-events: none;
        opacity: .5;
      }
    }
  }

  .game__controls-actions {
		display: flex;
    justify-content: center;
    gap: 1rem;
    /* grid-template-columns: repeat(4, minmax(43px, 1fr)); */
    /* padding-bottom: 10px; */
    margin-top: 1rem;

    span {
      cursor: pointer;
      //aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      //font-size: 1.5rem;
      position: relative;
      gap: .5rem;

      &[disabled] {
        &:hover {
          background-color: orange;
        }

        opacity: .5;
        cursor: none;
        pointer-events: none;
      }

      .mode {
        &.digit {
          background-color: green;
        }

        &.note {
          background-color: red;
        }
      }
    }
  }

  .game__controls-numpad {
		display: flex;
    /* grid-template-columns: repeat(3, minmax(43px, 1fr)); */
    justify-content: center;
    gap: 1rem;

    span {
	    cursor: pointer;
	    /* aspect-ratio: 2 / 1; */
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    font-size: 1.5rem;
	    /* border: 1px solid; */
	    padding: 0 1rem;

      &:hover {
        //background-color: #0101c2;
      }

      &[disabled] {
        &:hover {
          //background-color: #18d39e;
        }

        opacity: .5;
        cursor: none;
        pointer-events: none;
      }
    }
  }

  .grid {
    gap: 1px;
    display: grid;
    grid-template-columns: repeat(9, minmax(43px, 1fr));
    background-color: $color5;
    position: relative;

    &__border {
      position: absolute;
      background-color: black;

      &.x {
        height: 1px;
        width: 100%;
      }

      &.y {
        width: 1px;
        height: 100%;
      }
    }

    &__cell {
      background-color: #fff;
      aspect-ratio: 1 / 1;
      display: grid;
      position: relative;

      &.candidates {
        grid-template-rows: repeat(3, minmax(0, 1fr));
        grid-template-columns: repeat(3, minmax(0, 1fr));

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          &.error {
            color: darkred;
          }
        }
      }

      &.highlight-regions.selected-x, &.highlight-regions.selected-y, &.highlight-regions.selected-block {
        background-color: $color6;

//         &:not(.error) {
//           background-color: #f3f4f7;
//         }
      }

      &.selected-x.selected-y {
        //background-color: blanchedalmond !important;
        //&.match-tip {
        //  color: unset !important;
        //}

        &:after {
					content: "";
			    border-radius: 50%;
			    border: 2px solid;
			    height: 90%;
			    width: 90%;
			    position: absolute;
			    top: 5%;
			    left: 5%;
        }
      }

      &.match-tip, &.match-value {
        color: $color14;

        &:after {
					content: "";
			    border-radius: 50%;
			    border: 2px dashed;
			    height: 90%;
			    width: 90%;
			    position: absolute;
			    top: 5%;
			    left: 5%;
        }
      }

      &.error {
        color: $color11;

        &:after {
					content: "";
			    border-radius: 50%;
			    border: 2px dashed;
			    height: 90%;
			    width: 90%;
			    position: absolute;
			    top: 5%;
			    left: 5%;
        }
      }

      &.is-tip {
        &.error {
          color: unset;
        }

        //font-weight: bold;
      }

      span.number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }
    }
  }
}
